import { AllPsychotype } from 'ui-kit/icons/types';

// https://stackoverflow.com/a/7091965 может быть скос в пару дней до ДР
export const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getLetters = (psychotypes: AllPsychotype[]) => {
  return psychotypes.reduce((text, psychotype) => {
    return text + psychotype;
  }, '');
};

export const getHumanDate = (date: Date | string): string =>
  new Date(date).toLocaleDateString();
