import { useRouter } from 'next/router';

import { useGetUserQuery } from 'store/api';

import Preloader from 'components/preloader';

import { resctrictedPages } from './constants';

import styles from './styles.module.scss';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useRouter();
  const { isSuccess } = useGetUserQuery();

  const isAuthorizedRoute = !isSuccess && resctrictedPages.includes(pathname);

  if (isAuthorizedRoute) {
    return (
      <div className={styles.layout}>
        <Preloader />
      </div>
    );
  }

  return <main className={styles.layout}>{children}</main>;
};

export default Layout;
