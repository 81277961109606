import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import organizationReducer from 'reducers/organizationSlice';
import api from 'store/api';

const store = configureStore({
  reducer: {
    organization: organizationReducer,

    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([api.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);

export default store;
