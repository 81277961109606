import { toast } from 'react-toastify';

import errorIcon from 'public/static/error-alert.svg';
import infoIcon from 'public/static/info-alert.svg';
import successIcon from 'public/static/success-alert.svg';

import { AlertType } from './type';

const ERROR_MESSAGE = 'Что-то пошло не так. Попробуйте повторить позже.';
const ERROR_HEADER = 'Ошибка!';

const SUCCESS_MESSAGE = 'Все изменения были сохранены.';
const SUCCESS_HEADER = 'Ура!';

const INFO_MESSAGE = 'Вы всегда можете связаться со службой поддержки.';
const INFO_HEADER = 'Обратите внимание!';

export const ALERT_STATUS_DATA = {
  [AlertType.success]: {
    message: SUCCESS_MESSAGE,
    header: SUCCESS_HEADER,
    icon: successIcon,
    method: toast.success,
  },
  [AlertType.error]: {
    message: ERROR_MESSAGE,
    header: ERROR_HEADER,
    icon: errorIcon,
    method: toast.error,
  },
  [AlertType.info]: {
    message: INFO_MESSAGE,
    header: INFO_HEADER,
    icon: infoIcon,
    method: toast.info,
  },
};
