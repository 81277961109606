import React, { FC } from 'react';

import clsx from 'clsx';

import img from 'public/static/circle-preloader.svg';

import styles from './styles.module.scss';

const Preloader: FC = () => {
  return (
    <div className={clsx(styles.wrapper)}>
      <img className={clsx(styles.image)} src={img.src} alt="Загрузка" />
    </div>
  );
};

export default Preloader;
