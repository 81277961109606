import Head from 'next/head';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import store from 'store';

import Layout from 'components/layout';
import AlertInfo from 'ui-kit/alert-info';

import 'styles/fonts.scss';
import 'styles/global.scss';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
        />
        <title>maven.id</title>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      </Head>
      <Provider store={store}>
        <AlertInfo />
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  );
}

export default MyApp;
