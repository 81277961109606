import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationType } from 'types/organization';
import { RegistrationInputNamesEnum } from 'types/registration';

export type OrganizationInfoType = Pick<
  OrganizationType,
  | 'name'
  | 'id'
  | 'registrationAllowed'
  | 'allowProfileEdit'
  | 'unrequiredFields'
>;

const initialState: OrganizationInfoType = {
  name: null,
  id: '',
  registrationAllowed: null,
  allowProfileEdit: null,
  unrequiredFields: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationInfo(
      state,
      { payload }: PayloadAction<OrganizationInfoType>,
    ) {
      state.name = payload.name;
      state.id = payload.id;
      state.registrationAllowed = payload.registrationAllowed;
      state.allowProfileEdit = payload.allowProfileEdit;
    },
    setUnrequiredFields(
      state,
      { payload }: PayloadAction<RegistrationInputNamesEnum[]>,
    ) {
      state.unrequiredFields = payload;
    },
    setOrganizationReset() {
      return {
        ...initialState,
      };
    },
  },
});
export const {
  setOrganizationInfo,
  setOrganizationReset,
  setUnrequiredFields,
} = organizationSlice.actions;
export default organizationSlice.reducer;
