export enum Links {
  login = '/login',
  home = '/home',
  registration = '/registration',
  resetPassword = '/reset_password',
  updateProfile = '/home/update-profile',
  updatePassword = '/home/update-password',
  organization = '/',
  email_confirmation = '/email_confirmation',
  update_password = '/update_password',
  alert = '/alert',
}
