export const lexics = {
  api: {
    response: {
      default: {
        success: '',
        error: {
          default:
            'Соединение с удаленным сервером прервано. Проверьте интернет соединение',
        },
      },
      updateClient: {
        success: 'Мы успешно обновили информацию о клиенте',
        error: {
          default: 'При обновлении клиента прозошла ошибка',
        },
      },
      getUser: {
        success: '',
        error: {
          default: '',
        },
      },
      updateUserPassword: {
        success: 'Мы успешно сохранили ваш пароль',
        error: {
          default: '',
          403: 'Старый пароль введен неверно',
        },
      },
      updateUserInformation: {
        success: 'Мы успешно обновили ваши данные',
        error: {
          default: '',
        },
      },
      register: {
        denied: 'Регистрация заблокирована',
      },
      organization: {
        error: 'Организация заблокирована. Обратитесь к администратору',
      },
      login: {
        denied: 'Пользователь заблокирован. Обратитесь к администратору',
      },
    },
  },
};
